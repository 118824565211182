import { Payment } from '../../../payment-interface/shared/models/payment.interface';

export interface Invoice {
  id: number;
  uuid: string;
  invoiceId?: number;
  invoiceJson?: InvoicePayload;
  invoiceNumber: number;
  reference: string;
  peopleId: number;
  isAnInvoice: boolean;
  amountExcludedTax: number;
  amountIncludedTax: number;
  ltaUuid: number;
  vatAmount: number;
  vat: number;
  payload: InvoicePayload;
  status: string;
  currency?: string;
  concessionId?: number;
  localId?: number;
  callbackUrl: string;
  payment?: Array<Payment>;
  paymentId?: number;
  invoicedAt?: string;
  extraData?: { vatReachedAt: string };
}

export interface InvoicePayload {
  metadata: Metadata;
  data: Data;
}

interface Metadata {
  date: string;
  reference: string;
  name: string;
  type: string;
  typeFacture: string;
  typeDoc: string;
  lang: string;
  devise: string;
}

interface Data {
  entete: Entete;
  detail: Array<DetailItem>;
  totalDetail: TotalDetail;
  detailDeductions: Array<DeductionItem>;
  totalDeductions: TotalDeductions;
  legal: Legal;
}

interface Entete {
  nameE: string;
  serviceE: string;
  rueE: string;
  rueDetailE: string;
  villeE: string;
  cpE: number;
  nameD: string;
  rueD: string;
  villeD: string;
  cpD: number;
  dateDoc: string;
  numDoc: string;
  netPayerE: number;
  netPayerD: number;
  montantTtcLigneT: number;
  montantTvaLigneT: number;
  tauxTvaLigneT: number;
  montantHtLigneT: number;
  iban: null;
  bic: null;
  refVirementCheque: string;
  nomCheque: string;
  addresseCheque: string;
  addresseCheque2: string;
  villeCheque: string;
  cpCheque: string;
  infoCheque: string;
  mentionMandatFacturation: string;
  mailE: string;
  tphoneE: string;
  serviceD?: string;
  rueDetailD?: string;
}

interface DetailItem {
  texte: string;
  montantHt: number;
  tauxTva: number;
  montantTva: number;
  montantTtc: number;
  lineItems: Array<LineItemsItem>;
}

interface LineItemsItem {
  texte: string;
  litigationObject: string;
  quantite: number;
  montantHt: number;
  tauxTva: number;
  montantTva: number;
  montantTtc: number;
  prixUnitaire: number;
  parPeriod: string;
}

interface TotalDetail {
  quantite: number;
  montantHt: number;
  montantTva: number;
  montantTtc: number;
  tauxTva: number;
}

interface DeductionItem {
  texte: string;
  montantADeduire: number;
  deductionItems: Array<DeductionItemsItem>;
}

interface DeductionItemsItem {
  texte: string;
  montantADeduire: number;
}
interface TotalDeductions {
  montantADeduire: number;
}

interface Legal {
  mentionsLegales: string;
  legalText: string;
  taxLegalText: string;
  condPaiement: string;
  autoliquidation: string;
}

export enum InvoiceType {
  businessContribution = 'businessContribution',
  transactionSeller = 'transactionSeller',
  transactionBuyer = 'transactionBuyer',
  transactionLessor = 'transactionLessor',
  transactionTenant = 'transactionTenant',
}
